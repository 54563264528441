import React from "react";
import useTranslations from "../../../../components/useTranslations";
import Footer from "../../../../components/Footer/Footer";
import SupportLeftNav from "../../../../components/TwoColumnLeftNav/SupportLeftNav";

const Catchall = () => {
	// useTranslations is aware of the global context (and therefore also "locale")
	// so it'll automatically give back the right translations
	const { catchall } = useTranslations();

	return (
		<>
			<article className="two-column-left-nav">
				<SupportLeftNav />
				<section className="main-content">
					<h2 className={"large bold"}>{catchall}</h2>
					<div className="content-container  catchall">
						<h2>
							輸出貿易管理令に対する弊社製品の該当、非該当のご説明とお願い
						</h2>
						<p className="date">2003年6月25日</p>
						<p>
							弊社製、温度、湿度、気圧および気象機器の指示器、ならびに記録計は、輸出貿易管理令の16項キャッチオール規制品目に該当します。
							<br />
							規制対象品目の該当項目の部は18部。「類」（HSコード*）は90類と91類に該当となります。
							経済産業省作成のチェックシート例では以下になります。
							<br />
							（*HSコード：Harmonized System Code）
						</p>
						<p>
							「キャッチオール規制」の関連する経済産業省ＨＰは以下の通りです。下記チェックシート例もここから入手していただけます。
						</p>
						<a
							className="link"
							href="http://www.meti.go.jp/policy/anpo/anpo03.html"
						>
							http://www.meti.go.jp/policy/anpo/anpo03.html
						</a>
						<div className="checklist">
							<h3>該非判断チェックリスト</h3>
							<p>
								(1) 輸出令別表第１外為令別表の1～15項のいずれかに該当するか。
								<br />
								⇒いいえ、該当しません。
							</p>
							<p>
								(2)
								HSコードから当該貨物・技術が輸出令別表第1外為令別表の16項の中欄に該当しないことが明らかか。
								<br />
								⇒いいえ、該当します。（HSコードは第90類、第91類に該当）
							</p>
						</div>
						<p>
							以下（3）の「キャッチオール規制（16項）に係わる用途・需要者チェックシート」項目へ進んでください。
							項目（3）以下は実際に輸出される方によるチェックで、弊社では関与できません。
						</p>
						<p>
							弊社製品を輸出される場合は「輸出相手国（仕向け地）」ならびに「客観用件」を事前にお調べ頂きますようお願い申し上げます。
						</p>
						<p className="company-name">
							株式会社佐藤計量器製作所
							<br />
							本社：営業本部
						</p>
					</div>
				</section>
			</article>
			<Footer />
		</>
	);
};

export default Catchall;
